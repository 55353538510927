// Generated by Framer (61bac00)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["eyUg1vJYY"];

const serializationHash = "framer-msAtd"

const variantClassNames = {eyUg1vJYY: "framer-v-13sq6df"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, width, ...props}) => { return {...props, q4Tl71JqI: image ?? props.q4Tl71JqI ?? {src: "https://framerusercontent.com/images/sGmRTBpDw9KWqTe1VaGNBRKhek.svg"}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, q4Tl71JqI, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "eyUg1vJYY", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-13sq6df", className, classNames)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"eyUg1vJYY"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><Image background={{alt: "", fit: "fill", sizes: "80px", ...toResponsiveImage(q4Tl71JqI)}} className={"framer-ec7mc1"} layoutDependency={layoutDependency} layoutId={"LCitmf1mW"}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-msAtd [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-msAtd .framer-o63oo8 { display: block; }", ".framer-msAtd.framer-13sq6df { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 20px 20px 20px 20px; position: relative; width: min-content; }", ".framer-msAtd .framer-ec7mc1 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 80px); overflow: hidden; position: relative; width: 80px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-msAtd.framer-13sq6df { gap: 0px; } .framer-msAtd.framer-13sq6df > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-msAtd.framer-13sq6df > :first-child { margin-left: 0px; } .framer-msAtd.framer-13sq6df > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 120
 * @framerIntrinsicWidth 120
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"q4Tl71JqI":"image"}
 * @framerImmutableVariables true
 */
const FramerZo0TRrLIw: React.ComponentType<Props> = withCSS(Component, css, "framer-msAtd") as typeof Component;
export default FramerZo0TRrLIw;

FramerZo0TRrLIw.displayName = "Tokens Logos";

FramerZo0TRrLIw.defaultProps = {height: 120, width: 120};

addPropertyControls(FramerZo0TRrLIw, {q4Tl71JqI: {__defaultAssetReference: "data:framer/asset-reference,sGmRTBpDw9KWqTe1VaGNBRKhek.svg?originalFilename=multiversx.svg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerZo0TRrLIw, [])